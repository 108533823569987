import { render, staticRenderFns } from "./EmployeeManagement.vue?vue&type=template&id=613da420&scoped=true&"
import script from "./EmployeeManagement.vue?vue&type=script&lang=js&"
export * from "./EmployeeManagement.vue?vue&type=script&lang=js&"
import style0 from "./EmployeeManagement.vue?vue&type=style&index=0&id=613da420&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "613da420",
  null
  
)

export default component.exports